import React from "react"

import { DriveLayout } from "components/layout-custom"
import TripsAdmin from "components/trips"

const Page = () => (
  <DriveLayout title="Trips" padded={false}>
    <TripsAdmin allowAdminActions={false} />
  </DriveLayout>
)

export default Page
